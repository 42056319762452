.rootLoadingSpinner {
  height: 5px;
  width: 5px;
  color: #fff;
}
  [data-theme="light"] .rootLoadingSpinner {
    color: #161212;
  }
  .rootLoadingSpinner {

  margin: auto;
  display: flex;
  align-items: center;
  box-shadow: -10px -10px 0 5px, -10px -10px 0 5px, -10px -10px 0 5px,
    -10px -10px 0 5px;
  animation: loader-38 3s infinite;
}

@keyframes loader-38 {
  0% {
    box-shadow: -10px -10px 0 5px, -10px -10px 0 5px, -10px -10px 0 5px,
      -10px -10px 0 5px;
  }
  8.33% {
    box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px -10px 0 5px,
      10px -10px 0 5px;
  }
  16.66% {
    box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
      10px 10px 0 5px;
  }
  24.99% {
    box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
      -10px 10px 0 5px;
  }
  33.32% {
    box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
      -10px -10px 0 5px;
  }
  41.65% {
    box-shadow: 10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
      10px -10px 0 5px;
  }
  49.98% {
    box-shadow: 10px 10px 0 5px, 10px 10px 0 5px, 10px 10px 0 5px,
      10px 10px 0 5px;
  }
  58.31% {
    box-shadow: -10px 10px 0 5px, -10px 10px 0 5px, 10px 10px 0 5px,
      -10px 10px 0 5px;
  }
  66.64% {
    box-shadow: -10px -10px 0 5px, -10px -10px 0 5px, 10px 10px 0 5px,
      -10px 10px 0 5px;
  }
  74.97% {
    box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
      -10px 10px 0 5px;
  }
  83.3% {
    box-shadow: -10px -10px 0 5px, 10px 10px 0 5px, 10px 10px 0 5px,
      -10px 10px 0 5px;
  }
  91.63% {
    box-shadow: -10px -10px 0 5px, -10px 10px 0 5px, -10px 10px 0 5px,
      -10px 10px 0 5px;
  }
  100% {
    box-shadow: -10px -10px 0 5px, -10px -10px 0 5px, -10px -10px 0 5px,
      -10px -10px 0 5px;
  }
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.react-loading-skeleton::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  );
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .react-loading-skeleton {
    --pseudo-element-display: none; /* Disable animation */
  }
}

